<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center pb-8 border-b-2 border-gray-200">
      <h3 class="text-base md:text-18 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Change profile password
      </h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          :to="{ name: routeNames.profile }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-xs ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 overflow-y-auto">
      <Form
        autocomplete="off"
        class="w-full px-1"
        :validation-schema="profileChangePasswordSchema"
        @submit="onSubmitForm"
      >
        <div class="w-full">
          <p class="font-medium text-left mb-4 text-blue-600 whitespace-normal">Change password</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2 pl-0 md:pr-8">
              <AppInput
                id="password"
                v-model="profileModel.password"
                type="password"
                name="password"
                placeholder=""
                label="Old password"
                required
              />
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2 pl-0 md:pr-8">
              <AppInput
                id="newPassword"
                v-model="profileModel.newPassword"
                type="password"
                name="newPassword"
                placeholder=""
                label="New password"
                required
              />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8">
              <AppInput
                id="confirmNewPassword"
                v-model="profileModel.confirmNewPassword"
                type="password"
                name="confirmNewPassword"
                placeholder=""
                label="Confirm new password"
                required
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-center pt-24 pb-24 md:pb-40">
          <div class="w-full md:w-1/2">
            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Submit
            </AppButton>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Form } from 'vee-validate'
import { useRouter } from 'vue-router'

import { useProfile } from '@/core/compositions'
import { notification } from '@/core/helpers'
import { routeNames } from '@/router'
import { profileChangePasswordSchema } from '@/core/schemas'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'

export default defineComponent({
  name: 'ChangePasswordProfile',

  components: {
    Form,
    AppButton,
    AppInput
  },

  setup () {
    const router = useRouter()

    const { profileModel, changePassword } = useProfile()

    const loading = ref(false)

    const onSubmitForm = async () => {
      loading.value = true

      try {
        await changePassword()

        notification({ type: 'success', title: 'Success!', message: 'Password successfully updated!' })

        router.push({ name: routeNames.profile })
      } catch (err) {
        console.log(err)
        notification({ title: 'Password updating error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    return {
      routeNames,
      profileChangePasswordSchema,
      loading,
      profileModel,
      onSubmitForm
    }
  }
})
</script>
